@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.placeholder-bold::placeholder {
  font-weight: bold;
}
