/* Custom media query for 400x850 screen size */
@media (max-width: 400px) and (max-height: 850px) {
  .navbar-links {
    display: none; /* Hide all nav links */
  }
}
/* Navbar2.css */

nav {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it's above other elements */
  width: 100%;
}

/* Custom styling for your navbar */
.navbar-links a {
  margin-right: 1rem;
}

/* .shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */
